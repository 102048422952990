<template>
  <div id="page-dialogs">
    <div>
      <v-breadcrumbs :items="breadcrumbs">
        <v-icon slot="divider">forward</v-icon>
      </v-breadcrumbs>
    </div>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md6 lg6>
           <base-material-card
          color="primary"
          icon="library_books"
          title="Cambiar Contraseña"
          class="elevation-1 px-5 py-3"
        >

            <v-card-text>
              <v-form>
                <v-container grid-list-md text-xs-center>
                <v-text-field
                  :append-icon="iconpass2"
                  @click:append="mostrarPass2"
                  :rules="[rules.required]"
                  name="login"
                  label="Ingresar Nueva Contrseña"
                  autocomplete="new-password"
                  :type="passwordFieldType2"
                  class="input-group--focused secure"
                  v-model="model.pass_new"
                ></v-text-field>
                <v-text-field
                  :append-icon="iconpass3"
                  :rules="[rules.required]"
                  @click:append="mostrarPass3"
                  name="password"
                  label="Confirmar Nueva Contraseña"
                  autocomplete="new-password"
                  :type="passwordFieldType3"
                  class="input-group--focused secure"
                  v-model="model.pass_confirm"
                ></v-text-field>
                <v-chip
                v-if="model.pass_confirm != model.pass_new"
                outlined
                color="red"
                ><v-icon color="red"> error</v-icon>Contraseñas no
                coinciden..</v-chip
              >
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>

              <v-spacer></v-spacer>
              <v-btn
                block
                color="primary"
                v-bind:disabled="
                  model.pass_confirm === '' ||
                    model.pass_confirm != model.pass_new
                "
                @click="actualizar"
                :loading="loading"
                >Actualizar</v-btn
              >
            </v-card-actions>
                        </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>

export default {
  data: () => ({ fab: false,
    breadcrumbs: [
      {
        text: "Inicio",
        disabled: false,
        to: "/index"
      },
      {
        text: "Cambiar Contraseña",
        disabled: true,
        href: ""
      }
    ],
    loading: false,
    passwordFieldType1: "password",
    passwordFieldType2: "password",
    passwordFieldType3: "password",
    iconpass1: "fa-eye",
    iconpass2: "fa-eye",
    iconpass3: "fa-eye",
    rules: {
      required: value => !!value || "El campo no puede ir vacio"
    },
    model: {
      pass_old: "",
      pass_new: "",
      pass_confirm: ""
    },
    user: {
            _id: "",
            _rev: "",
            name: "",
            password: "",
            type: "user",
            nombre: "",
            roles: "",
            estatus: true
    },
  }),

  methods: {
    mostrarPass1: function(evt) {
      this.passwordFieldType1 =
        this.passwordFieldType1 === "password" ? "text" : "password";
      this.iconpass1 =
        this.passwordFieldType1 === "password" ? "fa-eye" : "fa-eye-slash";
    },
    mostrarPass2: function(evt) {
      this.passwordFieldType2 =
        this.passwordFieldType2 === "password" ? "text" : "password";
      this.iconpass2 =
        this.passwordFieldType2 === "password" ? "fa-eye" : "fa-eye-slash";
    },
    mostrarPass3: function(evt) {
      this.passwordFieldType3 =
        this.passwordFieldType3 === "password" ? "text" : "password";
      this.iconpass3 =
        this.passwordFieldType3 === "password" ? "fa-eye" : "fa-eye-slash";
    },
     actualizar: function () {
            let id_usuario = this.$local_storage.get("sb_usuario");
            window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + "/_users/org.couchdb.user:" + id_usuario)
                .then(response => {
                    this.user._id = response.data._id;
                    this.user._rev = response.data._rev;
                    this.user.name = response.data.name;
                    this.user.nombre = response.data.nombre;
                    this.user.roles = response.data.roles;
                    this.user.estatus = response.data.estatus;
                    this.user.sucursal = response.data.sucursal;
                    this.user.perfil = response.data.perfil;
                    this.user.password = this.model.pass_confirm;
                    let data = this.user;
                    window.dialogLoader.show('Espere un momento por favor..');
                    window.axios
                        .put(process.env.VUE_APP_COUCHDB_URL + '/_users/' + data._id+ '?conflicts=true', data)
                        .then(response => {
                            this.$swal({
                              type: "success",
                              title: "Operación Exitosa",
                              text: "Tendra que iniciar sesion nuevamente",
                              footer: ""
                          })
                            this.cerrarSesion();
                        })
                        .catch(error => {
                            console.log(error);
                            window.dialogLoader.showSnackbar('Ocurrio un error al actualizar la contraseña..', {
                                color: 'error'
                            });
                        }).then(() => {
                            window.dialogLoader.hide();
                        });


                })
                .catch(error => {
                     window.dialogLoader.showSnackbar('Ocurrió un error al obtener al usuario', {
                            color: 'error'
                        });
                });
        },


  }
};
</script>
<style scoped lang="css">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
</style>
